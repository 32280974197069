import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a7de992e = () => interopDefault(import('../pages/aanmelden.vue' /* webpackChunkName: "pages/aanmelden" */))
const _53aeceba = () => interopDefault(import('../pages/direct/index.vue' /* webpackChunkName: "pages/direct/index" */))
const _beb4f53e = () => interopDefault(import('../pages/genres/index.vue' /* webpackChunkName: "pages/genres/index" */))
const _4386db5a = () => interopDefault(import('../pages/gratis-sexfilms/index.vue' /* webpackChunkName: "pages/gratis-sexfilms/index" */))
const _490457dd = () => interopDefault(import('../pages/modellen/index.vue' /* webpackChunkName: "pages/modellen/index" */))
const _cf6a45d0 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _72372831 = () => interopDefault(import('../pages/overzicht/index.vue' /* webpackChunkName: "pages/overzicht/index" */))
const _29191627 = () => interopDefault(import('../pages/privefilmpjes/index.vue' /* webpackChunkName: "pages/privefilmpjes/index" */))
const _5857d7c8 = () => interopDefault(import('../pages/series/index.vue' /* webpackChunkName: "pages/series/index" */))
const _3779041a = () => interopDefault(import('../pages/sexfilms/index.vue' /* webpackChunkName: "pages/sexfilms/index" */))
const _4a1c3f94 = () => interopDefault(import('../pages/transactie.vue' /* webpackChunkName: "pages/transactie" */))
const _54f22541 = () => interopDefault(import('../pages/tv-gids/index.vue' /* webpackChunkName: "pages/tv-gids/index" */))
const _e3eebda0 = () => interopDefault(import('../pages/webcamsex/index.vue' /* webpackChunkName: "pages/webcamsex/index" */))
const _0ed3e9a5 = () => interopDefault(import('../pages/zoekresultaten/index.vue' /* webpackChunkName: "pages/zoekresultaten/index" */))
const _27b07ece = () => interopDefault(import('../pages/account/direct.vue' /* webpackChunkName: "pages/account/direct" */))
const _3c16d939 = () => interopDefault(import('../pages/account/gegevens-voorkeuren.vue' /* webpackChunkName: "pages/account/gegevens-voorkeuren" */))
const _1d444fc8 = () => interopDefault(import('../pages/account/gehuurd.vue' /* webpackChunkName: "pages/account/gehuurd" */))
const _238a24be = () => interopDefault(import('../pages/account/inloggen.vue' /* webpackChunkName: "pages/account/inloggen" */))
const _7de1a746 = () => interopDefault(import('../pages/account/mijn-credits.vue' /* webpackChunkName: "pages/account/mijn-credits" */))
const _1f0ce910 = () => interopDefault(import('../pages/account/mijn-favorieten.vue' /* webpackChunkName: "pages/account/mijn-favorieten" */))
const _a12b145c = () => interopDefault(import('../pages/account/registreren.vue' /* webpackChunkName: "pages/account/registreren" */))
const _519ea101 = () => interopDefault(import('../pages/account/wachtwoord-herstellen.vue' /* webpackChunkName: "pages/account/wachtwoord-herstellen" */))
const _e79cdb36 = () => interopDefault(import('../pages/account/wachtwoord-vergeten.vue' /* webpackChunkName: "pages/account/wachtwoord-vergeten" */))
const _4f476114 = () => interopDefault(import('../pages/acties/adventskalender/index.vue' /* webpackChunkName: "pages/acties/adventskalender/index" */))
const _711cd667 = () => interopDefault(import('../pages/acties/black-friday-2024/index.vue' /* webpackChunkName: "pages/acties/black-friday-2024/index" */))
const _15658ca0 = () => interopDefault(import('../pages/acties/droomvrouw-lexa/index.vue' /* webpackChunkName: "pages/acties/droomvrouw-lexa/index" */))
const _3054cdde = () => interopDefault(import('../pages/acties/energie-opwekkers/index.vue' /* webpackChunkName: "pages/acties/energie-opwekkers/index" */))
const _f00e6288 = () => interopDefault(import('../pages/acties/hollandszaad/index.vue' /* webpackChunkName: "pages/acties/hollandszaad/index" */))
const _2a84653c = () => interopDefault(import('../pages/acties/inmeikiesjij/index.vue' /* webpackChunkName: "pages/acties/inmeikiesjij/index" */))
const _29442836 = () => interopDefault(import('../pages/acties/kamasutra-2024/index.vue' /* webpackChunkName: "pages/acties/kamasutra-2024/index" */))
const _cd0fafc0 = () => interopDefault(import('../pages/acties/live-meekijken.vue' /* webpackChunkName: "pages/acties/live-meekijken" */))
const _494e16a3 = () => interopDefault(import('../pages/acties/live-meekijken/index.vue' /* webpackChunkName: "pages/acties/live-meekijken/index" */))
const _612381bf = () => interopDefault(import('../pages/acties/live-meekijken/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/live-meekijken/aanmeldformulier/index" */))
const _9666b8f8 = () => interopDefault(import('../pages/acties/live-meekijken/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/live-meekijken/aanmeldformulier/bedankt" */))
const _029ce3b2 = () => interopDefault(import('../pages/acties/parkeerplaatssex/index.vue' /* webpackChunkName: "pages/acties/parkeerplaatssex/index" */))
const _c405174a = () => interopDefault(import('../pages/acties/sexcamper/index.vue' /* webpackChunkName: "pages/acties/sexcamper/index" */))
const _39730347 = () => interopDefault(import('../pages/acties/sexmetkerst.vue' /* webpackChunkName: "pages/acties/sexmetkerst" */))
const _ece88eec = () => interopDefault(import('../pages/acties/sexmetkerst/index.vue' /* webpackChunkName: "pages/acties/sexmetkerst/index" */))
const _215f0ab8 = () => interopDefault(import('../pages/acties/sexmetkerst/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/sexmetkerst/aanmeldformulier/index" */))
const _5449d23d = () => interopDefault(import('../pages/acties/sexmetkerst/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/sexmetkerst/aanmeldformulier/bedankt" */))
const _0b30e06c = () => interopDefault(import('../pages/acties/valentijn/index.vue' /* webpackChunkName: "pages/acties/valentijn/index" */))
const _12fa2c0f = () => interopDefault(import('../pages/acties/webcamsex.vue' /* webpackChunkName: "pages/acties/webcamsex" */))
const _20fe6783 = () => interopDefault(import('../pages/acties/xl-deals-2024.vue' /* webpackChunkName: "pages/acties/xl-deals-2024" */))
const _3da7ccbe = () => interopDefault(import('../pages/betalen/direct.vue' /* webpackChunkName: "pages/betalen/direct" */))
const _4d124b9a = () => interopDefault(import('../pages/betalen/huurtegoed.vue' /* webpackChunkName: "pages/betalen/huurtegoed" */))
const _7d4a0c78 = () => interopDefault(import('../pages/info/algemene-voorwaarden.vue' /* webpackChunkName: "pages/info/algemene-voorwaarden" */))
const _26258690 = () => interopDefault(import('../pages/info/contact/index.vue' /* webpackChunkName: "pages/info/contact/index" */))
const _20de37ab = () => interopDefault(import('../pages/info/feedback/index.vue' /* webpackChunkName: "pages/info/feedback/index" */))
const _408b2143 = () => interopDefault(import('../pages/info/nieuwsbrief/index.vue' /* webpackChunkName: "pages/info/nieuwsbrief/index" */))
const _6f383e77 = () => interopDefault(import('../pages/info/over.vue' /* webpackChunkName: "pages/info/over" */))
const _3fd54d96 = () => interopDefault(import('../pages/info/privacy.vue' /* webpackChunkName: "pages/info/privacy" */))
const _87768838 = () => interopDefault(import('../pages/info/sex-link-partners.vue' /* webpackChunkName: "pages/info/sex-link-partners" */))
const _54c88a9b = () => interopDefault(import('../pages/info/tv-zender-ontvangen.vue' /* webpackChunkName: "pages/info/tv-zender-ontvangen" */))
const _67f6274a = () => interopDefault(import('../pages/info/uitschrijven/index.vue' /* webpackChunkName: "pages/info/uitschrijven/index" */))
const _7bd8cb48 = () => interopDefault(import('../pages/info/veelgestelde-vragen.vue' /* webpackChunkName: "pages/info/veelgestelde-vragen" */))
const _b186dbbe = () => interopDefault(import('../pages/acties/hollandszaad/actie-voorwaarden.vue' /* webpackChunkName: "pages/acties/hollandszaad/actie-voorwaarden" */))
const _10ebf393 = () => interopDefault(import('../pages/acties/hollandszaad/betaald.vue' /* webpackChunkName: "pages/acties/hollandszaad/betaald" */))
const _127718d9 = () => interopDefault(import('../pages/acties/hollandszaad/cadeau.vue' /* webpackChunkName: "pages/acties/hollandszaad/cadeau" */))
const _3e4d84cb = () => interopDefault(import('../pages/acties/hollandszaad/groeikaart.vue' /* webpackChunkName: "pages/acties/hollandszaad/groeikaart" */))
const _6ce1d200 = () => interopDefault(import('../pages/acties/hollandszaad/verstuurd.vue' /* webpackChunkName: "pages/acties/hollandszaad/verstuurd" */))
const _55e2b3b1 = () => interopDefault(import('../pages/acties/inmeikiesjij/gekozen.vue' /* webpackChunkName: "pages/acties/inmeikiesjij/gekozen" */))
const _04349490 = () => interopDefault(import('../pages/acties/parkeerplaatssex/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/parkeerplaatssex/aanmeldformulier/index" */))
const _68544b07 = () => interopDefault(import('../pages/acties/sexcamper/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/sexcamper/aanmeldformulier/index" */))
const _6976c910 = () => interopDefault(import('../pages/acties/valentijn/aanmeldformulier/index.vue' /* webpackChunkName: "pages/acties/valentijn/aanmeldformulier/index" */))
const _4160b815 = () => interopDefault(import('../pages/info/contact/bedankt.vue' /* webpackChunkName: "pages/info/contact/bedankt" */))
const _70b18e70 = () => interopDefault(import('../pages/info/feedback/bedankt.vue' /* webpackChunkName: "pages/info/feedback/bedankt" */))
const _58ca7208 = () => interopDefault(import('../pages/info/nieuwsbrief/bedankt.vue' /* webpackChunkName: "pages/info/nieuwsbrief/bedankt" */))
const _26672746 = () => interopDefault(import('../pages/info/nieuwsbrief/uitschrijven/index.vue' /* webpackChunkName: "pages/info/nieuwsbrief/uitschrijven/index" */))
const _c2cef5ac = () => interopDefault(import('../pages/info/uitschrijven/uitgeschreven.vue' /* webpackChunkName: "pages/info/uitschrijven/uitgeschreven" */))
const _503773d6 = () => interopDefault(import('../pages/acties/parkeerplaatssex/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/parkeerplaatssex/aanmeldformulier/bedankt" */))
const _9a1f8a68 = () => interopDefault(import('../pages/acties/sexcamper/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/sexcamper/aanmeldformulier/bedankt" */))
const _1b0e32fd = () => interopDefault(import('../pages/acties/valentijn/aanmeldformulier/bedankt.vue' /* webpackChunkName: "pages/acties/valentijn/aanmeldformulier/bedankt" */))
const _0af7c926 = () => interopDefault(import('../pages/info/nieuwsbrief/uitschrijven/uitgeschreven.vue' /* webpackChunkName: "pages/info/nieuwsbrief/uitschrijven/uitgeschreven" */))
const _755adf98 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c22237ce = () => interopDefault(import('../pages/genres/_slug.vue' /* webpackChunkName: "pages/genres/_slug" */))
const _46f41dea = () => interopDefault(import('../pages/gratis-sexfilms/_slug.vue' /* webpackChunkName: "pages/gratis-sexfilms/_slug" */))
const _474db695 = () => interopDefault(import('../pages/modellen/_slug.vue' /* webpackChunkName: "pages/modellen/_slug" */))
const _d2d78860 = () => interopDefault(import('../pages/nieuws/_slug.vue' /* webpackChunkName: "pages/nieuws/_slug" */))
const _708086e9 = () => interopDefault(import('../pages/overzicht/_slug.vue' /* webpackChunkName: "pages/overzicht/_slug" */))
const _276274df = () => interopDefault(import('../pages/privefilmpjes/_slug.vue' /* webpackChunkName: "pages/privefilmpjes/_slug" */))
const _56a13680 = () => interopDefault(import('../pages/series/_slug.vue' /* webpackChunkName: "pages/series/_slug" */))
const _35c262d2 = () => interopDefault(import('../pages/sexfilms/_slug.vue' /* webpackChunkName: "pages/sexfilms/_slug" */))
const _b5ee0f2a = () => interopDefault(import('../pages/webcamsex/_slug/index.vue' /* webpackChunkName: "pages/webcamsex/_slug/index" */))
const _5b4ff363 = () => interopDefault(import('../pages/webcamsex/_slug/live.vue' /* webpackChunkName: "pages/webcamsex/_slug/live" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aanmelden",
    component: _a7de992e,
    name: "aanmelden"
  }, {
    path: "/direct",
    component: _53aeceba,
    name: "direct"
  }, {
    path: "/genres",
    component: _beb4f53e,
    name: "genres"
  }, {
    path: "/gratis-sexfilms",
    component: _4386db5a,
    name: "gratis-sexfilms"
  }, {
    path: "/modellen",
    component: _490457dd,
    name: "modellen"
  }, {
    path: "/nieuws",
    component: _cf6a45d0,
    name: "nieuws"
  }, {
    path: "/overzicht",
    component: _72372831,
    name: "overzicht"
  }, {
    path: "/privefilmpjes",
    component: _29191627,
    name: "privefilmpjes"
  }, {
    path: "/series",
    component: _5857d7c8,
    name: "series"
  }, {
    path: "/sexfilms",
    component: _3779041a,
    name: "sexfilms"
  }, {
    path: "/transactie",
    component: _4a1c3f94,
    name: "transactie"
  }, {
    path: "/tv-gids",
    component: _54f22541,
    name: "tv-gids"
  }, {
    path: "/webcamsex",
    component: _e3eebda0,
    name: "webcamsex"
  }, {
    path: "/zoekresultaten",
    component: _0ed3e9a5,
    name: "zoekresultaten"
  }, {
    path: "/account/direct",
    component: _27b07ece,
    name: "Direct"
  }, {
    path: "/account/gegevens-voorkeuren",
    component: _3c16d939,
    name: "Gegevens & voorkeuren"
  }, {
    path: "/account/gehuurd",
    component: _1d444fc8,
    name: "Gehuurd"
  }, {
    path: "/account/inloggen",
    component: _238a24be,
    name: "account-inloggen"
  }, {
    path: "/account/mijn-credits",
    component: _7de1a746,
    name: "Mijn credits"
  }, {
    path: "/account/mijn-favorieten",
    component: _1f0ce910,
    name: "Mijn favorieten"
  }, {
    path: "/account/registreren",
    component: _a12b145c,
    name: "account-registreren"
  }, {
    path: "/account/wachtwoord-herstellen",
    component: _519ea101,
    name: "account-wachtwoord-herstellen"
  }, {
    path: "/account/wachtwoord-vergeten",
    component: _e79cdb36,
    name: "account-wachtwoord-vergeten"
  }, {
    path: "/acties/adventskalender",
    component: _4f476114,
    name: "acties-adventskalender"
  }, {
    path: "/acties/black-friday-2024",
    component: _711cd667,
    name: "acties-black-friday-2024"
  }, {
    path: "/acties/droomvrouw-lexa",
    component: _15658ca0,
    name: "acties-droomvrouw-lexa"
  }, {
    path: "/acties/energie-opwekkers",
    component: _3054cdde,
    name: "acties-energie-opwekkers"
  }, {
    path: "/acties/hollandszaad",
    component: _f00e6288,
    name: "acties-hollandszaad"
  }, {
    path: "/acties/inmeikiesjij",
    component: _2a84653c,
    name: "acties-inmeikiesjij"
  }, {
    path: "/acties/kamasutra-2024",
    component: _29442836,
    name: "acties-kamasutra-2024"
  }, {
    path: "/acties/live-meekijken",
    component: _cd0fafc0,
    children: [{
      path: "",
      component: _494e16a3,
      name: "acties-live-meekijken"
    }, {
      path: "aanmeldformulier",
      component: _612381bf,
      name: "acties-live-meekijken-aanmeldformulier"
    }, {
      path: "aanmeldformulier/bedankt",
      component: _9666b8f8,
      name: "acties-live-meekijken-aanmeldformulier-bedankt"
    }]
  }, {
    path: "/acties/parkeerplaatssex",
    component: _029ce3b2,
    name: "acties-parkeerplaatssex"
  }, {
    path: "/acties/sexcamper",
    component: _c405174a,
    name: "acties-sexcamper"
  }, {
    path: "/acties/sexmetkerst",
    component: _39730347,
    children: [{
      path: "",
      component: _ece88eec,
      name: "acties-sexmetkerst"
    }, {
      path: "aanmeldformulier",
      component: _215f0ab8,
      name: "acties-sexmetkerst-aanmeldformulier"
    }, {
      path: "aanmeldformulier/bedankt",
      component: _5449d23d,
      name: "acties-sexmetkerst-aanmeldformulier-bedankt"
    }]
  }, {
    path: "/acties/valentijn",
    component: _0b30e06c,
    name: "acties-valentijn"
  }, {
    path: "/acties/webcamsex",
    component: _12fa2c0f,
    name: "acties-webcamsex"
  }, {
    path: "/acties/xl-deals-2024",
    component: _20fe6783,
    name: "acties-xl-deals-2024"
  }, {
    path: "/betalen/direct",
    component: _3da7ccbe,
    name: "betalen-direct"
  }, {
    path: "/betalen/huurtegoed",
    component: _4d124b9a,
    name: "betalen-huurtegoed"
  }, {
    path: "/info/algemene-voorwaarden",
    component: _7d4a0c78,
    name: "info-algemene-voorwaarden"
  }, {
    path: "/info/contact",
    component: _26258690,
    name: "info-contact"
  }, {
    path: "/info/feedback",
    component: _20de37ab,
    name: "info-feedback"
  }, {
    path: "/info/nieuwsbrief",
    component: _408b2143,
    name: "info-nieuwsbrief"
  }, {
    path: "/info/over",
    component: _6f383e77,
    name: "info-over"
  }, {
    path: "/info/privacy",
    component: _3fd54d96,
    name: "info-privacy"
  }, {
    path: "/info/sex-link-partners",
    component: _87768838,
    name: "info-sex-link-partners"
  }, {
    path: "/info/tv-zender-ontvangen",
    component: _54c88a9b,
    name: "info-tv-zender-ontvangen"
  }, {
    path: "/info/uitschrijven",
    component: _67f6274a,
    name: "info-uitschrijven"
  }, {
    path: "/info/veelgestelde-vragen",
    component: _7bd8cb48,
    name: "info-veelgestelde-vragen"
  }, {
    path: "/acties/hollandszaad/actie-voorwaarden",
    component: _b186dbbe,
    name: "acties-hollandszaad-actie-voorwaarden"
  }, {
    path: "/acties/hollandszaad/betaald",
    component: _10ebf393,
    name: "acties-hollandszaad-betaald"
  }, {
    path: "/acties/hollandszaad/cadeau",
    component: _127718d9,
    name: "acties-hollandszaad-cadeau"
  }, {
    path: "/acties/hollandszaad/groeikaart",
    component: _3e4d84cb,
    name: "acties-hollandszaad-groeikaart"
  }, {
    path: "/acties/hollandszaad/verstuurd",
    component: _6ce1d200,
    name: "acties-hollandszaad-verstuurd"
  }, {
    path: "/acties/inmeikiesjij/gekozen",
    component: _55e2b3b1,
    name: "acties-inmeikiesjij-gekozen"
  }, {
    path: "/acties/parkeerplaatssex/aanmeldformulier",
    component: _04349490,
    name: "acties-parkeerplaatssex-aanmeldformulier"
  }, {
    path: "/acties/sexcamper/aanmeldformulier",
    component: _68544b07,
    name: "acties-sexcamper-aanmeldformulier"
  }, {
    path: "/acties/valentijn/aanmeldformulier",
    component: _6976c910,
    name: "acties-valentijn-aanmeldformulier"
  }, {
    path: "/info/contact/bedankt",
    component: _4160b815,
    name: "info-contact-bedankt"
  }, {
    path: "/info/feedback/bedankt",
    component: _70b18e70,
    name: "info-feedback-bedankt"
  }, {
    path: "/info/nieuwsbrief/bedankt",
    component: _58ca7208,
    name: "info-nieuwsbrief-bedankt"
  }, {
    path: "/info/nieuwsbrief/uitschrijven",
    component: _26672746,
    name: "info-nieuwsbrief-uitschrijven"
  }, {
    path: "/info/uitschrijven/uitgeschreven",
    component: _c2cef5ac,
    name: "info-uitschrijven-uitgeschreven"
  }, {
    path: "/acties/parkeerplaatssex/aanmeldformulier/bedankt",
    component: _503773d6,
    name: "acties-parkeerplaatssex-aanmeldformulier-bedankt"
  }, {
    path: "/acties/sexcamper/aanmeldformulier/bedankt",
    component: _9a1f8a68,
    name: "acties-sexcamper-aanmeldformulier-bedankt"
  }, {
    path: "/acties/valentijn/aanmeldformulier/bedankt",
    component: _1b0e32fd,
    name: "acties-valentijn-aanmeldformulier-bedankt"
  }, {
    path: "/info/nieuwsbrief/uitschrijven/uitgeschreven",
    component: _0af7c926,
    name: "info-nieuwsbrief-uitschrijven-uitgeschreven"
  }, {
    path: "/",
    component: _755adf98,
    name: "index"
  }, {
    path: "/genres/:slug",
    component: _c22237ce,
    name: "genres-slug"
  }, {
    path: "/gratis-sexfilms/:slug?",
    component: _46f41dea,
    name: "gratis-sexfilms-slug"
  }, {
    path: "/modellen/:slug",
    component: _474db695,
    name: "modellen-slug"
  }, {
    path: "/nieuws/:slug",
    component: _d2d78860,
    name: "nieuws-slug"
  }, {
    path: "/overzicht/:slug",
    component: _708086e9,
    name: "overzicht-slug"
  }, {
    path: "/privefilmpjes/:slug",
    component: _276274df,
    name: "privefilmpjes-slug"
  }, {
    path: "/series/:slug",
    component: _56a13680,
    name: "series-slug"
  }, {
    path: "/sexfilms/:slug",
    component: _35c262d2,
    name: "sexfilms-slug"
  }, {
    path: "/webcamsex/:slug",
    component: _b5ee0f2a,
    name: "webcamsex-slug"
  }, {
    path: "/webcamsex/:slug/live",
    component: _5b4ff363,
    name: "webcamsex-slug-live"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
